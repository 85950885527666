<template>
  <div class="row">
    <div class="col-12">
      <ibox
        :title="
          `${$route.params.shop_name} ${$route.params.shop_lang} ${$t(
            'orders'
          )}`
        "
      >
        <template slot="content" slot-scope="{ setLoading }">
          <data-table
            :url="
              `/${$route.params.shop_name}/${$route.params.shop_lang}/orders`
            "
            :columns_list="columns_list"
            @loading="setLoading"
          />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox";
import DataTable from "@/components/DataTable/DataTable.vue";
import { php } from "locutus";

export default {
  name: "NNPLSell",
  components: {
    Ibox,
    DataTable
  },
  data() {
    return {
      columns_list: [
        // {
        //     key: 'order_id',
        //     name: 'Szczegóły',
        //     filter: 'btn',
        //     btn: a => `<a href="#/pl/neonail/kody-rabatowe/${a}"><i class="fa fa-edit"></i></a>`
        // },
        {
          key: "number",
          name: "Kod",
          type: "text",
          sortable: true
        },
        {
          key: "status",
          name: "Typ",
          type: "text",
          sortable: true
        },
        {
          key: "date",
          name: "Data wygenerowania",
          type: "date",
          sortable: true
        },
        {
          key: "order_amount",
          name: "Kwota zamówienia",
          type: "text",
          sortable: true
        },
        {
          key: "confirmed_ip",
          name: "IP",
          type: "text",
          sortable: true
        },
        {
          key: "adress",
          name: "Klient",
          type: "text",
          filter: "function",
          col_function: a => {
            if (a) {
              const unserializedData = php.var.unserialize(a);
              return `<p>
                                        <strong>${unserializedData.name} ${
                unserializedData.surname
              }</strong></br>
                                        <strong>${
                                          unserializedData.company
                                        }</strong></br>
                                        <strong>${
                                          unserializedData.email
                                        }</strong></br>
                                        Faktura: <strong>${
                                          unserializedData.invoice === "on"
                                            ? this.$t("yes")
                                            : this.$t("no")
                                        }</strong></br>
                                    </p>`;
            }
          },
          sortable: true
        },
        {
          key: "payment_delivery",
          name: "Płatność i dostawa",
          type: "text",
          filter: "function",
          col_function: a => {
            if (a) {
              const unserializedData = php.var.unserialize(a);
              return `<p>
                                        Sposób dostawy: <strong>${unserializedData.delivery_name_pl}</strong></br>
                                        Sposób płatności: <strong>${unserializedData.payment_name}</strong></br>
                                    </p>`;
            }
          },
          sortable: true
        },
        {
          key: "discount_code",
          name: "Kod rabatowy",
          type: "text",
          filter: "function",
          col_function: a => {
            if (a) {
              const unserializedData = php.var.unserialize(a);
              return `<p>
                                        Kod: <strong>${unserializedData.code}</strong></br>
                                        Typ: <strong>${unserializedData.code_type}</strong></br>
                                        Wartość: <strong>${unserializedData.discount}${unserializedData.discount_symbol}</strong></br>
                                        ${unserializedData.message}</br>
                                    </p>`;
            }
          },
          sortable: true
        },
        {
          key: "confirmed",
          name: "Potwierdzony",
          type: "list",
          list: [
            {
              id: null,
              name: this.$t("all")
            },
            {
              id: 1,
              name: this.$t("yes")
            },
            {
              id: 0,
              name: this.$t("no")
            }
          ],
          filter: "boolean",
          sortable: true
        },
        {
          key: "notice_adm",
          name: "Notatki",
          type: "text",
          sortable: true
        }
      ]
    };
  }
};
</script>
